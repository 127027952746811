import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

class Item extends Component {

    data = this.props.Data;
    language = this.props.Language;

    state = {
        isLoading: true
    };
    
    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 2000);
    }

    render() {

        const { isLoading } = this.state;

        return (
            <>
                {
                    isLoading ? 
                    <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#8a4628" >
                        <Skeleton height={150} containerClassName="flex-1" />
                    </SkeletonTheme>
                    : 
                    <div className="category-item" style={ { backgroundImage :`url(${this.data.cover})`, backgroundSize: 'cover', backgroundPosition: 'center' , filter:"brightness(0.9)", width: (this.props.Index+1) % 3 === 0 ? '100%' : ''} }>
                        <Link to={"/menu/" + this.data['id']}>
                            <h2> 
                                {
                                    this.props.Language == "en" ? this.data['name_eng']
                                    : this.props.Language == "krd" ? this.data['name_krd']
                                    : this.data['name_ar']
                                }
                            </h2>
                        </Link>
                    </div>
                }
                
            </>
        );
    }
}

export default Item;